.root {
    position: relative;
    left: -120px;
}
.input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 12px 20px;
    width: 400px;
    border-radius: 10px;
    font-size: 16px;
    padding-left: 42px;

    &:focus {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
}
.icon {
    height: 22px;
    width: 22px;
    opacity: 0.3;
    position: absolute;
    left: 14px;
    top: 12px;
}
.clearIcon {
    width: 18px;
    height: 18px;
    opacity: 0.3;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;

    &:hover{
        opacity: 0.8;
    }
}