.root {
    padding: 100px;
    text-align: center;
    min-width: 750px;
    margin: 0 auto;

    span {
        font-size: 64px;
    }
}
.description {
    font-size: 22px;
}